import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useTheme } from '../context/themeContext'
import { Button } from './button'
import '../css/_hero.scss'

const Hero = ({ data }) => {
  const title = data?.title
  const summary = data?.summary
  const image = data?.image
  const imageDark = data?.imageDark
  const button = data?.dynamicButton
  const { theme } = useTheme()

  return (
    <div className={'banner hero container'} id={'hero-' + data.id}>
      <div className="title container-md">
        <div className="col-12 col-lg-6">
          <h1>{title}</h1>
          <ReactMarkdown source={summary} className="banner-markdown" />
          {button ? (
            <Button
              icon={button?.icon}
              customButton={button?.customButton}
              button={button?.button}
              classContainer={'button'}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className={`imagen col-12 col-lg-6`}>
        {image?.url ? (
          <img
            src={theme === 'dark' && imageDark ? imageDark?.url : image?.url}
            alt={title}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Hero
