import React from 'react'
import PropTypes from 'prop-types'

const CustomBackgroundLeftArrow = ({ onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left custom-arrow left background"
    ></button>
  )
}
export default CustomBackgroundLeftArrow

CustomBackgroundLeftArrow.propTypes = {
  onClick: PropTypes.func.isRequired
}
