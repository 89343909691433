import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../font-awesome'
import { useTheme } from '../../../context/themeContext'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'

export const TabsContent = ({ data, title }) => {
  const { theme } = useTheme()
  
  function CustomToggle({ children, eventKey }) {
    const [activeStates, setActiveState] = useState(false)
    
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      setActiveState(!activeStates)
    })

    let classActive = activeStates ? 'active' : ''
    
    return (
      <button
        type="button"
        className={`btn ${classActive}`}
        onClick={decoratedOnClick}
      >
        {children}
        <FontAwesomeIcon icon={['fas', 'chevron-down']} size="sm" />
      </button>
    )
  }

  const tabs = data?.map((item) => (
    <Tab eventKey={item.id} title={item.title} key={item.id}>
      <ReactMarkdown
        source={item.content}
        escapeHtml={false}
        className="markdown-format-title"
      />

      <Accordion defaultActiveKey="0">
        {item?.itemsAccordion?.map((accordion) => (
          <Card key={accordion.id} className={`${theme === 'dark' ? "tabDark" : ""}`}>
            <Card.Header>
              <CustomToggle eventKey={accordion.id}>
                <p className="mb-0 text-left">{accordion.title}</p>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={accordion.id}>
              <Card.Body>
                <ReactMarkdown
                  source={accordion.content}
                  escapeHtml={false}
                  className="markdown-format"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </Tab>
  ))

  return (
    <div className="container tabs-component pb-5">
      <h2 className="title-secondary text-uppercase font-weight-extra-bold ucc-text-blue">
        {title}
      </h2>
      <div className="py-4">
        <Tabs defaultActiveKey={data[0].id} id="uncontrolled-tab-example">
          {tabs}
        </Tabs>
      </div>
    </div>
  )
}

TabsContent.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
  eventKey: PropTypes.number
}
