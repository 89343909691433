import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { findUrl } from '../../helpers/findUrl'

const MosaicItem = ({ innerData }) => {
  const paths = useStaticQuery(graphql`
    query {
      allSitePage(
        filter: {
          componentChunkName: {
            in: [
              "component---src-templates-main-render-ou-js"
              "component---src-templates-internal-page-js"
            ]
          }
        }
      ) {
        nodes {
          path
        }
      }
    }
  `)

  const { allSitePage: matchPages } = paths
  const backgroundStyle = {
    backgroundImage: `url(${innerData.backgroundImage?.url || 'none'})`
  }

  return innerData.visible ? (
    <section className="col-lg-4 col-md-6 col-sm-12 mb-4">
      <div
        className="mosaic-items"
        role="img"
        alt={innerData.backgroundImageName || ''}
        style={backgroundStyle}
      >
        <div className="mosaic-items-filter">
          <h3>{innerData.cardName}</h3>
          <ReactMarkdown source={innerData.content} />
          <button type="button">
            {innerData.page ? (
              <Link
                to={findUrl(innerData.page.slug, matchPages.nodes)}
                className="btn btn-outline-light px-4 fit-content mt-auto rounded-pill text-uppercase eventuality-button-font-size font-weight-medium font-secondary"
              >
                VER MÁS
              </Link>
            ) : innerData.url && !innerData.page && !innerData.destacado ? (
              <a
                href={innerData.url}
                className="btn btn-outline-light px-4 fit-content mt-auto rounded-pill text-uppercase eventuality-button-font-size font-weight-medium font-secondary"
                target="_blank"
                rel="noreferrer"
              >
                VER MÁS
              </a>
            ) : null}
          </button>
        </div>
      </div>
    </section>
  ) : null
}

export default MosaicItem

MosaicItem.propTypes = {
  innerData: PropTypes.object
}
