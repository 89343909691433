import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import Icon from './../../icon'
import { useTheme } from '../../../context/themeContext'
import '../../../css/_iconsText.scss'

export const IconsInfo = ({ data }) => {
  const { theme } = useTheme()

  const iconsText = data.map((block) => {
    const ButtonIcon = () => block.icon ? <Icon style={{ marginRight: '1em', marginLeft: '1em' }} iconSize="fa-2x" {...block.icon} /> : null
    
    return (
      <div
        key={block.id}
        className="iconsTextBlock my-auto py-3 d-flex flex-column flex-md-row gap-2 gap-md-3"
      >
        <ButtonIcon />
        <div className="markdown-wrapper">
          <span className="d-block fw-medium">
            <ReactMarkdown className="markdown-format">
              {block.content}
            </ReactMarkdown>
          </span>
        </div>
      </div>
    )
  })

  return (
    <section>
      <div className="container">
        <div className="d-flex justify-content-center">
          <div style={{ zIndex: 1 }} className="my-5 col-sm-11">
            <div className="shadow-sm row-cols-2 row-cols-md-4 p-3 py-md-0 row justify-content-around iconsTextSection">
              {iconsText}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

IconsInfo.propTypes = {
  data: PropTypes.array.isRequired
}
