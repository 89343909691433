import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Container } from 'react-bootstrap'
import icon from '../../../images/googleCalendarBLue.jpg'

// Cards is a component created for to get better experience of user
import Cards from './calendarCard'

export const Calendar = ({ calendarPreview }) => {
  const urls = calendarPreview.calendarUrl

  useEffect(() => {
    var calendarContainer = document.getElementById(
      `calendar-container-${calendarPreview.id}`
    )

    var codeCalendar = calendarPreview.code.code

    if (calendarContainer !== null) {
      calendarContainer.innerHTML = codeCalendar
    }
  }, [calendarPreview.code.code])

  return (
    <div className="mb-5 container">
      <Container className="w-100 h-100 d-flex align-items-center justify-content-between calendar-mobile-iframe">
        <Container className="embed-responsive">
          <Cards
            imageSource={icon}
            title={`Ir a ${calendarPreview.code.title}`}
            url={urls}
          ></Cards>
        </Container>
      </Container>

      <Card className="calendar-card">
        <Card.Body
          id={`calendar-container-${calendarPreview.id}`}
          className=" w-100 shadow-sm rounded"
        ></Card.Body>
      </Card>
    </div>
  )
}

Calendar.propTypes = {
  calendarPreview: PropTypes.object.isRequired
}
