import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { createNavigationUrl } from '../helpers/createNavigationUrl'
import '../css/_bannerList.scss'

const BannerList = ({ data }) => {
  const title = data?.title
  const pages = useStaticQuery(graphql`
    query {
      allSitePage(
        filter: {
          componentChunkName: {
            in: [
              "component---src-templates-main-render-ou-js"
              "component---src-templates-internal-page-js"
            ]
          }
        }
      ) {
        nodes {
          path
        }
      }
    }
  `)
  const { allSitePage: matchPages } = pages

  const addLink = (item) => {
    if (item.page || item.organizational_unit || item.url) {
      return (
        <Link to={createNavigationUrl(matchPages.nodes, item)}>
          <h4>{item.title}</h4>
          <p>{item.description}</p>
        </Link>
      )
    } else {
      return (
        <>
          <h4>{item.title}</h4>
          <ReactMarkdown
            escapeHtml={false}
            source={item.description}
            className="format-markdown-container fade-in-image mt-0"
          />
        </>
      )
    }
  }

  const cards = data?.Card.map((item) => {
    return (
      <div key={'bannerCard-' + item.id} className="card_item d-flex mb-2" id={'bannerCard - ' + item.id}>
        {item.icon && (
          <div className="card_item">
            <img className="d-block" src={item.icon?.url} placeholder="blurred" />
          </div>
        )}
        <div className="card_item col-9 pe-2">{addLink(item)}</div>
      </div>
    )
  })

  return (
    <div className="container pt-5" id={'bannerList - ' + data.id}>
      <div className="bannerList d-md-flex flex-row">
        <h1 className="bannerList__title col-md-6 col-xl-6 align-self-center mb-4">
          {title}
        </h1>
        <div className="bannerList__cards col-md-6 col-xl-6">{cards}</div>
      </div>
    </div>
  )
}

export default BannerList
