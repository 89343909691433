import React from 'react'
import PropTypes from 'prop-types'

export const SingleImage = ({ singleImage }) => {
  return (
    <div className="container w-100 mb-5">
      <img
        src={singleImage.image.url}
        alt={singleImage.title}
        className="img-fluid"
      />
    </div>
  )
}

SingleImage.propTypes = {
  singleImage: PropTypes.object.isRequired
}
