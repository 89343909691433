import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export const Audio = ({ audio, audiosVisibleLength }) => {
  const [state, setState] = useState('')
  let queryString = ''

  useEffect(() => {
    let openDiv =
      '<div class="iframe-audio-container border rounded shadow-sm d-flex flex-column justify-content-center align-items-center">'
    let closeDiv = '</div></div>'

    for (let index = 0; index < 3; index++) {
      if (audio[index]) {
        queryString =
          queryString +
          (audio[index].visible
            ? `<div class="audio-mq-fix" style="width: ${
                audiosVisibleLength === 1
                  ? '100%'
                  : audiosVisibleLength === 2
                  ? '48%'
                  : '30%'
              }"><div><h5>${audio[index].title}</h5></div>` +
              openDiv +
              audio[index].code
                .replace('height="300"', 'height="100"')
                .replace(
                  '<div style="font-size: 10px',
                  '<div style="font-size: 10px; width:100%;'
                )
                .replaceAll('#cccccc', '#888888;padding-left:2px') +
              closeDiv
            : '')
      }
    }
    setState(queryString)
  }, [])

  return (
    <div
      id="soundcloud-container"
      className="container mb-5 d-flex justify-content-between align-items-center"
      dangerouslySetInnerHTML={{
        __html: state
      }}
    ></div>
  )
}

Audio.propTypes = {
  audio: PropTypes.array.isRequired,
  audiosVisibleLength: PropTypes.number.isRequired
}
