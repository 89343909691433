import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import '../../css/_textComponent.scss'

const TextContent = ({ component }) => {
  const content = component.textContent?.content
  const title = component.textContent?.title
  const bgImage = component.textContent?.backgroundImage?.url

  return (
    <div
      className="container-text mt-3 mt-xl-5 container"
      style={{
        backgroundImage: `url(${bgImage})`
      }}
    >
      {component.textContent.variant === 'contained' ? (
        <div className="container text d-flex flex-column flex-md-row gap-xl-5">
          <div className="title">
            <h2 className="titleText pt-5 ps-md-0 pt-md-3">{title}</h2>
          </div>
          <ReactMarkdown
            source={content}
            className="description"
            style={{ margin: !bgImage && '0rem' }}
          />
        </div>
      ) : (
        <div className="mb-5">
          <h2 className="title-secondary text-uppercase font-weight-extra-bold ucc-text-blue">
            {title}
          </h2>
          <ReactMarkdown
            escapeHtml={false}
            className="markdown-format space"
            source={content}
          />
        </div>
      )}
    </div>
  )
}

export default TextContent

TextContent.propTypes = {
  component: PropTypes.object.isRequired
}
