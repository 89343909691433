import React from 'react'
import PropTypes from 'prop-types'

const CustomBackgroundRightArrow = ({ onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right custom-arrow right background"
    ></button>
  )
}

export default CustomBackgroundRightArrow

CustomBackgroundRightArrow.propTypes = {
  onClick: PropTypes.func.isRequired
}
