import { graphql, useStaticQuery } from 'gatsby'
import React from "react"
import { Button } from './button'
import ButtonLink from "../components/buttonLink"
import "../css/_quote.scss"
import ReactMarkdown from "react-markdown"

const Quote = ({
  data: { description, title, variant, videoUrl, dynamicButton, image, strapi_component, id, professional},
}) => {
    const datas = useStaticQuery(graphql`
      query {
        allSitePage(
            filter: {
          componentChunkName: {
            in: [
                "component---src-templates-main-render-ou-js"
                "component---src-templates-internal-page-js"
                ]
            }
            }
          ) {
          nodes {
              path
          }
        }
      }
    `)
  const { 
    allSitePage: matchPages,
  } = datas

  const button = dynamicButton

  const url = videoUrl?.replace("watch?v=", "embed/")
  let code = url?.substring(url.lastIndexOf("/") + 1, url.length)
  const codeIndex = code?.indexOf("?")

  if (codeIndex !== -1 && code !== undefined) {
    code = code.substring(0, code.indexOf("?"))
  }    

  return (
    <div className="container mb-3 mb-lg-5" id={strapi_component + "-" + id}>
      <section className={`quote variant-${variant} py-lg-4`}>
        {(image && !videoUrl) && (
          <div className="quote-body">
            <img
              src={image.url}
              alt={image.name}
            />
          </div>
        )}

        {(videoUrl !== null && videoUrl !== undefined) && (
          <div className="quote-body">
            {(url !== undefined && code !== undefined) && (
              <iframe
              loading="lazy"
              type="text/html"
              srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;
                width:100%;height:100%;object-fit: cover;top:0;bottom:0;max-height: 500px}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;margin:auto;text-shadow:0 0 0.5em black}</style>
                <a href=${url  + "?rel=0"}>
                <img src=https://img.youtube.com/vi/${code}/hqdefault.jpg alt='Video'>
                <span>▶</span></a>`}
              src={url + "?rel=0"}
              frameBorder="0"
              allowFullScreen
              title="benefits_video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            ></iframe>

            )}
            
          </div>
        )}

        <div className="quote-person">
          <h4 className="quote-person-title">{title}</h4>
          <ReactMarkdown source={description} className="quote-person-text" />

          {professional.profilePicture && (
            <div className="quote-profile make-it-fast my-3 my-md-2 my-xl-4 d-flex gap-3 justify-content-between">
              <img
                src={professional.profilePicture.url}
                alt="quote author"
              />
              <div className="flex-grow-1 align-self-center">
                <ReactMarkdown source={professional.name} className="markdown" />
              </div>
            </div>
          )}
          {button ? (
            <Button
              icon={button?.icon}
              customButton={button?.customButton}
              button={button?.button}
              classContainer={'button'}
            />
          ) : (
            <></>
          )}
        </div>
      </section>
    </div>
  )
}

Quote.defaultProps = {
  description: "",
  title: "",
  variant: "",
  videoUrl: "",
  button: {},
  image: {},
}

export default Quote