import React, { useEffect, useState, useLayoutEffect } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { searchCareer } from '../../helpers/searchCareer'
import { matchOrganizationByLevel } from '../../helpers/matchOrganizationByLevel'
import { CAREER_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'
import '../../css/_careerFeedComponent.scss'


const CareerFeedComponent = ({ title, careerLevelFilter, careerPlacesFilter, careerModalityFilter }) => {
  
  const data = useStaticQuery(graphql`
    query($careerlevelId: Int) {
      allStrapiIcdaCareer {
        nodes {
          name
          slug
          main {
            headerImages {
              image {
                url
              }
            }
          }
          career_category {
            name
          }
        }
      }
      allStrapiOrganizationalUnit {
        nodes {
          id
          name
          slug
          color
          ucc_careers {
            id
          }
          header {
            slide {
              backgroundImage {
                url
              }
            }
          }
        }
      }
      allStrapiUccCareer {
        nodes {
          strapiId
          name
          slug
          modality
          career_category {
            id
            name
          }
          organizational_unit {
            id
          }
          ucc_career_places {
            title
          }
        }
      }
      allStrapiCareerCategory(
        filter: { career_level: { id: { eq: $careerlevelId } } }
      ) {
        nodes {
          strapiId
          name
          career_level {
            id
            name
          }
        }
      }
    }
  `)


  const { 
    allStrapiCareerCategory: allCareerCategories
  } = data

  const [ organizationsByCareers, setOrganizationsByCareers ] = useState([])
  const [ careersFiltered, setCareersFiltered] = useState([]);

  const categories = allCareerCategories.nodes

  // Busco la relacion de OU con las carreras
  useLayoutEffect(() => {
    const updatedOrganizationsByCareers = matchOrganizationByLevel(data);
    setOrganizationsByCareers(updatedOrganizationsByCareers);
  }, [data])

  useEffect(() => {
    if (organizationsByCareers.length > 0) {
      const filterResult = searchCareer(
        organizationsByCareers,
        "",
        categories,
        careerLevelFilter,
        careerPlacesFilter,
        careerModalityFilter
      );
    
      setCareersFiltered(filterResult)
    }
  }, [organizationsByCareers]);

  return (
    <div className='academic-offer-component'>
      <div style={{width: "100%"}}>
        <h2 className="title pt-0 mt-2 mb-4">{title}</h2>
      </div>
      
      {careersFiltered.length > 0 ? 
        <div className="container p-0 row d-flex align-items-stretch">
          {careersFiltered.map((organization) => {
            return feedCarrerCreator(
              organization,
              categories
            )
          })}
        </div>
        :   
        <div className="container p-0 row d-flex align-items-stretch">
          <h3>Aún no se cargó la Propuesta Académica</h3>
        </div>
      }
    </div>
  )
}

const generateCareerLink = (organization, career) => {
  const baseUrl =
    organization.name === 'ICDA - Escuela de Negocios'
      ? `https://icda.ucc.edu.ar/${CAREER_GENERAL_ROUTE}/${career.slug}`
      : `/${CAREER_GENERAL_ROUTE}/${career.slug}`

  const target = organization.name === 'ICDA - Escuela de Negocios' ? '_blank' : '_self'

  return (
    <Link
      to={baseUrl}
      className="list-group-item cursor-pointer fade-in-image text-decoration-none"
      key={`career-key-${career.name}`}
      target={target}
    >
      {career.name} 
    </Link>
  )
}


// Building Feed Propuesta Academica
const feedCarrerCreator = (organization, categories, uccCareerPlaces) => {

  var espCsSalud = false
  
  return organization.careers.length > 0 ? (
    <div className="mb-4 col-12 col-md-6 col-lg-4" key={`key-feed-career-${organization.name}`}>
      <div className="card h-100 shadow-sm fade-in-image">
        {/* Portada de OU o ICDA */}
        <Link
          to={
            organization.name === 'ICDA - Escuela de Negocios'
              ? `${organization.slug}`
              : `/${organization.slug}`
          }
          target="_blank"
          className="disable__link__styles"
        >
          <div
            className="w-100 news-image card-img-top d-flex flex-column align-items-end cursor-pointer "
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${
                organization.coverImg
                  ? organization.coverImg.url
                  : 'https://images.pexels.com/photos/159490/yale-university-landscape-universities-schools-159490.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
              }")`
            }}
          >
            <div className="card-body d-flex align-items-end align-self-start">
              <h4 className="card-title font-weight-bold  text-white text-uppercase">
                {organization.name}
              </h4>
            </div>
            <div
              className="w-100"
              style={{
                backgroundColor: organization.color,
                height: '8px'
              }}
            ></div>
          </div>
        </Link>
        {/* Listando carreras */}
        <ul className="list-group list-group-flush">
          {organization.careers
            .map((career) => {
              const category = categories.find((cat) => cat.strapiId === career.career_category.id);
              
              // exclude health specialties careers from academic offer
              if (category?.name === 'Especialidad Cs Salud' && espCsSalud === false) {
                espCsSalud = true

                const carrerCsSalud = {
                  name: "Especialidades Médicas",
                  slug: "../propuesta-academica/especialidades-cs-salud"
                }
                return generateCareerLink(organization, carrerCsSalud, uccCareerPlaces);
              } else if (category?.name !== 'Especialidad Cs Salud') {
                return generateCareerLink(organization, career, uccCareerPlaces);
              }
            })
          }
        </ul>
      </div>
    </div>
  ) : null
}

export default CareerFeedComponent

CareerFeedComponent.propTypes = {
  title: PropTypes.string.isRequired,
  careerLevelFilter: PropTypes.object.isRequired,
  careerPlacesFilter: PropTypes.object.isRequired,
  careerModalityFilter: PropTypes.object.isRequired
}
