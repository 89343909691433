
import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import ReactMarkdown from 'react-markdown'
import './font-awesome'
import '../css/_cardsCarousel.scss'
import CustomBackgroundLeftArrow from './customBackgroundArrowLeft'
import CustomBackgroundRightArrow from './customBackgroundArrowRight'

const CardsCarousel = ({ title, cards }) => {

  const cardsList = cards.map((card, i) => {
    return (
      <div
        key={`card-${i}`}
        className="cardContainer"
        style={{ backgroundColor: card.color }}
      >
        <ReactMarkdown className={`cardContainerText ${card.labelBlack ? "" : "text-white"}`}>
          {card.description}
        </ReactMarkdown>
      </div>
    )
  })

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3
    },
    mobileTablet: {
      breakpoint: { max: 767, min: 577 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1
    }
  }


  return (
    <div className="logos container py-3 py-md-5">
      {title && (
        <h2 className="text-uppercase font-weight-extra-bold pb-2 form-title">
          {title}
        </h2>
      )}

      <Carousel
        responsive={responsive}
        autoPlay={cardsList.length > 4}
        autoPlaySpeed={2000}
        infinite={cardsList.length > 4}
        containerClass={'containerCarrusel'}
        customRightArrow={<CustomBackgroundRightArrow />}
        customLeftArrow={<CustomBackgroundLeftArrow />}
        removeArrowOnDeviceType={cardsList.length <= 4 && ['tablet', 'desktop']}
      >
        {cardsList}
      </Carousel>
    </div>
  )
}

export default CardsCarousel
CardsCarousel.propTypes = {
  cards: PropTypes.array.isRequired,
  title: PropTypes.string
}
