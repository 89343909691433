import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../css/_maps.scss'

export const Maps = ({ title, maps }) => {

  return (
    <div className="mb-5 container">
      <h5 className='title-secondary'>{title}</h5>
      
      <Row className='maps'>
        {maps.map((mapContent, index) => {
          const isLast = index === maps.length - 1;

          if (mapContent.visible) {
            return ( 
              <React.Fragment key={mapContent.id}>
                {mapContent.mapContentText ? (  
                  <Col lg={12} xs={12}
                    key={mapContent.id}
                    id={`map-container-${mapContent.id}`}
                    className="w-100 shadow-sm rounded p-2 mb-2 d-flex flex-column"
                  >
                    <Row className='m-0'>
                      <Col lg={6} xs={12}
                        className='frame-section' 
                        dangerouslySetInnerHTML={{ __html: mapContent.code }} 
                        style={{ height: '490px', width: '100%' }} 
                      />
                      <Col lg={6} xs={12}>
                        <div className='d-flex align-items-center mb-2'>
                          <FontAwesomeIcon icon={["fas", "fa-location-dot"]} color={'red'} size='xl' className='mr-3' />
                          <h4 className='m-0'>{mapContent.title}</h4>
                        </div>
                        <div>
                          <h5 className='font-weight-bold'>Dirección:</h5>
                          <h6>{mapContent.mapContentText.address}</h6>
                        </div>
                        <div>
                          <h5 className='font-weight-bold'>Contacto:</h5>
                          <h6>{mapContent.mapContentText.contact}</h6>
                        </div>
                        <div>
                          <h5 className='font-weight-bold'>Horario de atención:</h5>
                          <h6>{mapContent.mapContentText.openingHours}</h6>
                        </div>
                        <div className='image-section'>
                          <img src={mapContent.mapContentText.image?.url} className='rounded-lg'/>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col lg={6} xs={12}
                    key={mapContent.id}
                    id={`map-container-${mapContent.id}`}
                    className="w-100 shadow-sm rounded p-2 mb-2 d-flex flex-column"
                  >
                    <div
                      className='frame-section mb-2' 
                      dangerouslySetInnerHTML={{ __html: mapContent.code }} 
                      style={{ height: '490px', width: '100%' }} 
                    />
                  <div className='d-flex align-items-center mb-2'>
                      <FontAwesomeIcon icon={["fas", "fa-location-dot"]} color={'red'} size='xl' className='mr-3' />
                      <h4 className='m-0'>{mapContent.title}</h4>
                    </div>
                  </Col>
                )}
                {!isLast && <hr />}
              </React.Fragment>
            )
          }
        })}
      </Row>
    </div>
  )
}

Maps.propTypes = {
  title: PropTypes.string,
  maps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      mapContentText: PropTypes.shape({
        address: PropTypes.string.isRequired,
        contact: PropTypes.string.isRequired,
        openingHours: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired
        })
      })
    })
  ).isRequired
}
