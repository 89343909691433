import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

export const PdfPreview = ({ pdfPreview }) => {
  useEffect(() => {
    const pdfContainer = document.getElementById(
      `pdf-container-${pdfPreview.id}`
    )
    const codePdf = pdfPreview.code
      .replace('height:0', 'height:400px')
      .replace('max(60%', 'max(40%')
    pdfContainer.innerHTML = codePdf
  }, [pdfPreview.code])

  return (
    <div className="mb-5 container">
      <h5 className='title-secondary'>{pdfPreview.title}</h5>
      <div
        id={`pdf-container-${pdfPreview.id}`}
        className=" w-100 shadow-sm rounded"
      ></div>
    </div>
  )
}

PdfPreview.propTypes = {
  pdfPreview: PropTypes.object.isRequired
}
