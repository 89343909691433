import React from 'react'
import '../../../css/_accordion.scss'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

export const Accordion = ({ data, title }) => {
  const accordionCards = data.map((card) => {
    return (
      <div className="card" key={card.id}>
        <a
          className="card-link "
          data-toggle="collapse"
          href={`#collapse${card.id}`}
        >
          <div className="card-header">{card.title}</div>
        </a>

        <div
          id={`collapse${card.id}`}
          className="collapse"
          data-parent="#accordion"
        >
          <div className="card-body">
            <ReactMarkdown className="markdown-format">
              {card.summary}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    )
  })

  return (
    <section className="py-3 py-md-5">
      <div className="container">
        <div className="row">
          <div className="text-center col">
            <h2 className="title-secondary text-uppercase font-weight-extra-bold ucc-text-blue pb-2">
              {title}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div id="accordion">{accordionCards}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

Accordion.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string
}
