import React from 'react'
import PropTypes from 'prop-types'
import '../../../css/_cards.scss'

function Card({ imageSource, title, url }) {
  return (
    <div className="card text-center bg-dark">
      <div className="overflow">
        <img src={imageSource} alt="a wallpaper" className="card-img-top" />
      </div>
      <div className="card-body text-light">
        <a
          href={url ? url : '#!'}
          target="_blank"
          className="border-5 btn btn-primary"
          rel="noopener noreferrer"
        >
          {title}
        </a>
      </div>
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  url: PropTypes.string,
  imageSource: PropTypes.string
}

export default Card
