import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useProfessionals from '../../../utils/querys/useProfessionals'
import '../../font-awesome'
import { useTheme } from '../../../context/themeContext'

export const Professionals = ({ title, professionals }) => {
  const { theme } = useTheme()
  const data = useProfessionals()
  const professionalList = data?.allStrapiProfesionals?.nodes
  
  const customProfessionalList = professionals.map((item) =>
    professionalList.find((prof) => prof.strapiId === item.professional.id)
  )

  return (
    professionals && (
      <div className="mb-5 container">
        
        {/* Titulo de Profesional List */}
        <div>
          <h3 className="title-secondary text-uppercase font-weight-extra-bold ucc-text-blue">{title}</h3>
        </div>

        <div className="row justify-content-around">
          {professionals.map(
            (professionals, index) =>
              professionals.visible && (
                <div
                  className={`col-12 col-sm-6 col-lg-3 mb-4 ${theme === 'dark' ? "profDark" : ""}`}
                  key={`professionalsId-${professionals.id}`}
                >
                  <div className="shadow-sm bg-white professional-card rounded px-4 pt-4 pb-2 h-100 d-flex flex-column align-items-center">
                    <div
                      role="img"
                      alt={professionals.professional?.profilePictureName || ''}
                      className="img-prof mb-3 bg-secondary"
                      style={{
                        backgroundImage: `${
                          professionals.professional.profilePicture
                            ? `url(${professionals.professional.profilePicture.url})`
                            : null
                        }`
                      }}
                    ></div>
                    <h6 className="text-center">
                      {professionals.professional.name}
                    </h6>
                    <h6 className="mb-3">
                      <small>{professionals.rol}</small>
                    </h6>
                    <div className="pt-2 border-top mb-2 flex-grow-1 d-flex flex-column">
                      <div className="pr-1 info-cont position-relative overflow-auto">
                        <p>{professionals.professional.description}</p>
                      </div>
                    </div>
                    {customProfessionalList[index]?.profesionalLinks.length >
                    0 ? (
                      <div className="social w-100 d-flex justify-content-center pt-2 border-top mb-2">
                        {customProfessionalList[index].profesionalLinks.map(
                          (item) => {
                            switch (item.type) {
                              case 'facebook':
                                return (
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    className="btn btn-social m-1 rounded-circle btn-social-icon btn-facebook"
                                    rel="noreferrer"
                                  >
                                    {' '}
                                    <FontAwesomeIcon
                                      icon={['fab', 'facebook-f']}
                                    />
                                  </a>
                                )
                              case 'youtube':
                                return (
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    className="btn btn-social m-1 rounded-circle btn-social-icon btn-youtube"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon
                                      icon={['fab', 'youtube']}
                                    />
                                  </a>
                                )
                              case 'linkedin':
                                return (
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    className="btn btn-social m-1 rounded-circle btn-social-icon btn-linkedin"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon
                                      icon={['fab', 'linkedin-in']}
                                    />
                                  </a>
                                )
                              case 'twitter':
                                return (
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    className="btn btn-social m-1 rounded-circle btn-social-icon btn-twitter"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon
                                      icon={['fab', 'twitter']}
                                    />
                                  </a>
                                )
                              case 'instagram':
                                return (
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    className="btn btn-social m-1 rounded-circle btn-social-icon btn-instagram"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon
                                      icon={['fab', 'instagram']}
                                    />
                                  </a>
                                )
                              case 'curriculum':
                                return (
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    className="btn btn-social m-1 rounded-circle btn-social-icon btn-curriculum"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon 
                                      icon={['fas', 'file']} 
                                    />
                                  </a>
                                )
                              case 'correo':
                                return (
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    className="btn btn-social m-1 rounded-circle btn-social-icon btn-correo"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon
                                      icon={['far', 'envelope']}
                                    />
                                  </a>
                                )
                              case 'otro':
                                return (  
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    className="btn btn-social m-1 rounded-circle btn-social-icon btn-other"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon icon={['fas', 'link']} />
                                  </a>
                                )
                            }
                          }
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    )
  )
}

Professionals.propTypes = {
  professionals: PropTypes.array.isRequired
}
