import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

export const Eventualities = ({
  eventualities,
  eventualityVisibleLength,
  eventualitiesName
}) => {
  return eventualities ? (
    <div className="mb-5 container">
      <div className="d-flex align-items-center mb-3">
        <div className="flex-grow-1">
          <h5 className="text-uppercase">
            <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
            {eventualitiesName ? eventualitiesName : 'Eventualidades'}
          </h5>
        </div>
      </div>
      <div className="row">
        {eventualities.map((eventualities) => {
          if (eventualities.visible) {
            return (
              <div
                className={`mb-4 col-12 ${
                  eventualityVisibleLength === 1
                    ? 'col-md-12'
                    : eventualityVisibleLength === 2
                    ? 'col-md-6'
                    : 'col-md-4'
                } ${eventualities.destacado ? 'hightlighted' : ''}`}
                key={`eventualitiesId-${eventualities.id}`}
              >
                <div className="h-100 d-flex flex-column rounded shadow-sm news-card overflow-hidden position-relative">
                  <div
                    role="img"
                    alt={eventualities.name || ''}
                    className="news-image"
                    style={{
                      backgroundImage: `url(${
                        eventualities.image ? eventualities.image.url : null
                      })`
                    }}
                  ></div>
                  <div className="flex-grow-1 p-4 d-flex flex-column news-info">
                    <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">
                      {eventualities.name}
                    </div>
                    <h5>{eventualities.title}</h5>
                    <ReactMarkdown escapeHtml={false} className="markdown-format" source={eventualities.description} />
                    <Link
                      to="/"
                      className="btn btn-outline-info mt-auto fit-content"
                    >
                      VER MÁS
                    </Link>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  ) : null
}

Eventualities.propTypes = {
  eventualities: PropTypes.array.isRequired,
  eventualityVisibleLength: PropTypes.number.isRequired,
  eventualitiesName: PropTypes.string.isRequired
}
