import React from 'react'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

export const Map = ({ map }) => {
  useEffect(() => {
    const mapContainer = document.getElementById(`map-container-${map.id}`)
    const codeMap = map.code.replace('width="600"', 'width="100%"')
    mapContainer.innerHTML = codeMap
  }, [map.code])

  return (
    <div className="mb-5 container">
      <h5 className='title-secondary'>{map.title}</h5>
      <div
        id={`map-container-${map.id}`}
        className=" w-100 shadow-sm rounded"
      ></div>
    </div>
  )
}

Map.propTypes = {
  map: PropTypes.object.isRequired
}
