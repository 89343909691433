import React from 'react'
import PropTypes from 'prop-types'
import MosaicItem from './mosaicItem'

const Mosaic = ({ data }) => {
  if (!data.visible) return null
  return (
    <div className="container my-5">
      <h2 className="text-center pb-2 title-secondary text-uppercase font-weight-extra-bold ucc-text-blue">{data.title}</h2>
      <section className="row justify-content-center">
        {data.cards.map((cardData) => (
          <MosaicItem key={cardData.id} innerData={cardData} />
        ))}
      </section>
    </div>
  )
}

Mosaic.propTypes = {
  data: PropTypes.object.isRequired
}

export default Mosaic
