import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const TextColumn = ({ title, leftText, rightText }) => {
  return (
    <div className="container py-3 py-md-5">
      {title && (
        <h2 className="text-center title-secondary text-uppercase font-weight-extra-bold ucc-text-blue pb-2">
          {title}
        </h2>
      )}

      <div className="row">
        <div className="col-md-6">
          <ReactMarkdown className="markdown-format">{leftText}</ReactMarkdown>
        </div>
        <div className="col-md-6">
          <ReactMarkdown className="markdown-format">{rightText}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
}

export default TextColumn

TextColumn.propTypes = {
  title: PropTypes.string,
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired
}
