import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { Video } from './video'

export const Publication = ({ data }) => {
  return (
    <div className="mb-5 container">
      {data.activateVideo ? (
        <div className="h-100 row rounded overflow-hidden">
          <div className="col-12 col-md-6">
            <Video videoComponent={data} />
          </div>
          <div className=" col-12 col-md-6 publication-content">
            <div className="flex-grow-1 d-flex align-items-center justify-content-center">
              <ReactMarkdown
                source={data.content}
                escapeHtml={false}
                className="markdown-format"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="h-100 row rounded overflow-hidden">
          <div className="col-12 col-md-6">
            <div
              role="img"
              alt={data.imageName || ''}
              className=" publication-image d-flex align-items-center justify-content-center"
              style={{
                backgroundImage: `url(${
                  data.image?.url ? data.image.url : null
                })`
              }}
            ></div>
          </div>
          <div className=" col-12 col-md-6 publication-content">
            <div className="flex-grow-1 d-flex align-items-center justify-content-center">
              <ReactMarkdown
                source={data.content}
                escapeHtml={false}
                className="markdown-format"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Publication.propTypes = {
  data: PropTypes.object.isRequired
}
