import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import { useForm } from '../hooks/useForm'
import {
  // validateForm,
  validateEmail,
  validatePhoneNumber
} from '../helpers/validateFormsFields'
import '../css/_contactForm.scss'
import postForm from '../services/postForm'
import PhoneInput from 'react-phone-number-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'

export const ContactForm = ({ formTitle, title, text }) => {
  const [values, handleInputChange, reset] = useForm({})
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alertForm, setAlertForm] = useState(false)
  const [alertFormError, setAlertFormError] = useState(false)
  // Instanciando codigo de pais
  const [phoneNumber, setPhoneNumber] = useState('+54')
  // Seteando variable del codigo pais
  const [phonePais, setPhonePais] = useState('+54')

  const { name, email, phone, ...rest } = values

  const valuesRequired = {
    // agent: 18286,
    agent: 19521, // agent prueba
    name,
    email,
    phone: phone !== undefined ? phone : phoneNumber,
    subject: 'Contacto',
    type: {
      option: 'Consulta general',
      type: 40637
    },
    content: rest
  }

  // Cambiando codigo pais al seleccionar otra bandera
  useEffect(() => {
    setPhonePais(phoneNumber)
    values.phone = undefined
    valuesRequired.phone = phoneNumber
  }, [phoneNumber])

  useEffect(() => {
    if (
      values.name?.length > 2 &&
      validateEmail(values.email) &&
      validatePhoneNumber(values.phone)
      // &&
      // validateForm(
      //   valuesRequired.content,
      //   form?.bodyForm?.length,
      //   bodyRequiredLength
      // )
    ) {
      return setValidated(true)
    } else {
      return setValidated(false)
    }
  }, [values])

  const handleSubmit = async (event) => {
    const formulario = event.currentTarget
    setLoading(true)
    event.preventDefault()

    try {
      const sendForm = await postForm(valuesRequired)

      if (sendForm.status) {
        setLoading(false)
        formulario.reset()
        reset()
        setAlertForm(true)
        setAlertFormError(false)
      } else {
        setLoading(false)
        setAlertForm(false)
        setAlertFormError(true)
      }
    } catch (error) {
      setLoading(false)
      setAlertForm(false)
      setAlertFormError(true)
    }
  }

  const handleClick = () => {
    setAlertForm(false)
  }

  const handleClickError = () => {
    setAlertFormError(false)
  }

  return (
    <div className="container">
      <div className="w-100 py-3 py-md-5 position-relative">
        {alertForm && (
          <div className="form-alert container">
            <div className="send-ok">
              <FontAwesomeIcon icon={faCheckCircle} color={'green'} />
              <span>Pedido realizado con exito</span>
              <button className="button-form" onClick={handleClick}>
                OK
              </button>
            </div>
          </div>
        )}

        {alertFormError && (
          <div className="form-alert container">
            <div className="send-error">
              <FontAwesomeIcon icon={faExclamationCircle} />
              <span>
                Error al enviar el formulario, intente nuevamente más tarde!
              </span>
              <button className="button-form" onClick={handleClickError}>
                OK
              </button>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-6">
            {title && (
              <h2 className="text-uppercase font-weight-extra-bold pb-2 form-title">
                {title}
              </h2>
            )}

            <ReactMarkdown className="markdown-format">{text}</ReactMarkdown>
          </div>
          <div className="col-md-6">
            {formTitle && (
              <h2 className="text-uppercase font-weight-extra-bold pb-2 form-title">
                {formTitle}
              </h2>
            )}
            <Form
              className="form-format"
              noValidate
              onSubmit={handleSubmit}
              validated={validated}
              id="form"
            >
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Group controlId={'validationCustom'} className="mb-2">
                    <Form.Label className='contactForm-label'>{'Nombre y Apellido' + ' *'}</Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      className="border-celeste"
                      minLength="2"
                      autoComplete="off"
                      required
                      placeholder={'Consulta de curso'}
                      onChange={handleInputChange}
                      isValid={values.name?.length > 2}
                      isInvalid={
                        values.name?.length > 0 && values.name?.length <= 2
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Ingrese un dato por favor
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={12} xs={12}>
                  <Form.Group controlId={'validationCustom'} className="mb-2">
                    <Form.Label className='contactForm-label'>{'Email' + ' *'}</Form.Label>
                    <Form.Control
                      name="email"
                      type="email"
                      autoComplete="off"
                      required
                      placeholder={'correo@correo.com'}
                      onChange={handleInputChange}
                      isValid={validateEmail(values.email)}
                      isInvalid={
                        values.email?.length > 0 && !validateEmail(values.email)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Ingrese un mail válido por favor
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={12} xs={12}>
                  <Form.Group controlId={'validationCustom'} className="mb-2">
                    <Form.Label className='contactForm-label'>{'Telefono' + ' *'}</Form.Label>
                    <div className="d-flex">
                      <PhoneInput
                        autoComplete="off"
                        international
                        className="custom-phone-input phone-num"
                        defaultCountry="AR"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                      />

                      <Form.Control
                        name="phone"
                        type="text"
                        className="phone-input"
                        value={
                          phonePais === phoneNumber
                            ? valuesRequired.phone
                            : phoneNumber
                        }
                        onChange={handleInputChange}
                        autoComplete="off"
                        required
                        isValid={validatePhoneNumber(valuesRequired.phone)}
                        isInvalid={
                          values.phone?.length > 2 &&
                          !validatePhoneNumber(valuesRequired.phone)
                        }
                      />
                    </div>
                  </Form.Group>
                </Col>
                {/* TextArea */}
                <Col lg={12} xs={12}>
                  <Form.Group controlId={'validationCustom'} className="mb-2">
                    <Form.Label className='contactForm-label'>{'Escribi tu consulta *'}</Form.Label>
                    <Form.Control
                      as="textarea"
                      name={'_Escribi tu consulta'}
                      required={false}
                      onChange={handleInputChange}
                      placeholder={'Escribi tu consulta'}
                      style={{ height: '80px' }}
                      minLength={3}
                      maxLength="300"
                      isValid={
                        valuesRequired.content['_Escribi tu consulta'] !==
                          undefined &&
                        valuesRequired.content['_Escribi tu consulta']?.length >
                          2
                      }
                      isInvalid={
                        valuesRequired.content['_Escribi tu consulta']?.length >
                          0 &&
                        valuesRequired.content['_Escribi tu consulta']
                          ?.length <= 2
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Group className="mb-2">
                    <div className="d-flex justify-content-end align-items-center pt-2">
                      <div>
                        <button
                          className="rounded-pill btn btn-sm btn-info px-4"
                          type="submit"
                          style={{
                            cursor:
                              !validated || loading ? 'not-allowed' : 'pointer'
                          }}
                          disabled={!validated || loading}
                        >
                          {!loading ? (
                            <span className="px-2">Enviar</span>
                          ) : (
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="pr-2">Enviando</span>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactForm.propTypes = {
  formTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string.isRequired
}
