export const ACCORDION_VERTICAL = 'dynamic-zone.accordion-vertical',
  AUDIO_LIST = 'pages-component.audio-list',
  BANNER_CAROUSEL = 'orgs-units.banner-carousel',
  BANNER_LIST = 'dynamic-zone.banner-list',
  BRICK = 'pages-component.image-and-text',
  BUILDING_LOCATION = 'institution.buildings-location',
  BUTTON = 'general-content.dynamic-button',
  CALENDAR = 'pages-component.calendar',
  CARD_LIST = 'orgs-units.card-list',
  CARDS_CAROUSEL = 'dynamic-zone.cards-carousel',
  CAREER_FEED = 'orgs-units.career-feed',
  CONTACT_FORM = 'dynamic-zone.contact-form',
  DIAGONAL_BANNER = 'dynamic-zone.diagonal-banner',
  DIARIES_LIST = 'relation-components.diaries-list',
  EVENTUALITIES_LIST = 'pages-component.eventualities-list',
  FORM_CONNECT = 'form.form-connect',
  GENERAL_FORM = 'general-content.form',
  HERO = 'dynamic-zone.hero',
  ICONS_CAROUSEL = 'dynamic-zone.icons-carousel',
  ICONS_TEXT = 'dynamic-zone.icon-text-block',
  INFO_CARDS = 'orgs-units.info-cards',
  LOGOS_SECTION = 'dynamic-zone.logos-section',
  MAP = 'pages-component.map',
  MAPS = 'dynamic-zone.maps',
  NOVELTIES_LIST = 'relation-components.novelties-list',
  PDF = 'pages-component.pdf',
  PROFESSIONALS = 'pages-component.profesional-list',
  QUOTE = 'dynamic-zone.quote',
  SINGLE_IMAGE = 'pages-component.header-image',
  SOCIAL_MEDIAS = 'orgs-units.social-medias',
  TABS = 'pages-component.tab',
  TEXT_COLUMN = 'general-content.text-column',
  TEXT_CONTENT_ORG_UNITS = 'orgs-units.text',
  TEXT_CONTENT_PAGES = 'pages-component.text',
  TRANSITION = 'dynamic-zone.transition',
  VIDEO = 'pages-component.video',
  VIDEO_BACKGROUND = 'general-content.video-background'
