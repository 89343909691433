import { graphql, useStaticQuery } from 'gatsby'

const useProfessionals = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiProfesionals {
        nodes {
          strapiId
          name
          profesionalLinks{
            id
            type
            url
          }
        }
      }
    }
  `)
  return query
}
export default useProfessionals