import { Link } from "gatsby"
import React from "react"

const ButtonLink = ({ button }) => {
  let internal = ""
  if(!button) return null
  if (button?.page) internal = button.page.slug
  if (internal) return <Link to={internal}>{button.content}</Link>
  else return <a href={button?.url} target="_blank" rel="noreferrer">{button.content}</a>
}

export default ButtonLink