import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import Icon from './icon'
import 'react-multi-carousel/lib/styles.css'
import './font-awesome'
import '../css/_iconsCarousel.scss'
import CustomLeftArrow from './customArrowLeft'
import CustomRightArrow from './customArrowRight'

const IconsCarousel = ({ title, icons }) => {
  const iconsList = icons.map((icon) => {
    const ButtonIcon = () =>
      icon.icon ? (
        <Icon
          style={{ marginRight: '1em', marginLeft: '1em' }}
          iconSize="fa-2x"
          {...icon.icon}
        />
      ) : null

    return (
      <div
        key={icon.id}
        className="iconsCarouselBlock my-auto py-3 py-md-4"
      >
        <ButtonIcon />
        <div className="markdown-wrapper">{icon.content}</div>
      </div>
    )
  })
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 768 },
      items: 3
    },
    mobileTablet: {
      breakpoint: { max: 767, min: 577 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1
    }
  }

  return (
    <div className="logos container py-3 py-md-5">
      {title && (
        <h2 className="text-uppercase font-weight-extra-bold pb-2 form-title text-center">
          {title}
        </h2>
      )}

      <Carousel
        responsive={responsive}
        autoPlay={iconsList.length > 3}
        autoPlaySpeed={2000}
        infinite={iconsList.length > 3}
        containerClass={'containerCarrusel'}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        removeArrowOnDeviceType={iconsList.length <= 3 && ['tablet', 'desktop']}
      >
        {iconsList}
      </Carousel>
    </div>
  )
}

export default IconsCarousel

IconsCarousel.propTypes = {
  icons: PropTypes.array.isRequired,
  title: PropTypes.string
}
