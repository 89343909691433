import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { createNavigationUrl } from '../helpers/createNavigationUrl'
import { useTheme } from '../context/themeContext'
import { Button } from './button'
import '../css/_diagonalBanner.scss'

const DiagonalBanner = ({ data }) => {
  const { theme } = useTheme()
  const title = data?.title
  const variant = data?.variant
  const image = data?.image
  const imageDark = data?.imageDark
  const button = data?.dynamicButton

  return (
    <div
      className={`banner ${variant} ${theme} container mb-5`}
      id={`${variant} - ` + data.id}
    >
      <div className={`title container-md ${theme}`}>
        <div className="col-12 col-lg-6">
          <h2> {title} </h2>
          {button ? (
            <Button
              icon={button?.icon}
              customButton={button?.customButton}
              button={button?.button}
              classContainer={'button'}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={`imagen col-12 ${
          variant === 'diagonal' ? 'col-md-8' : 'col-md-4'
        }`}
      >
        {image?.url ? (
          <img
            src={theme === 'dark' && imageDark ? imageDark?.url : image?.url}
            alt={title}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default DiagonalBanner
