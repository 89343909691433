import React from 'react'
import {
  ACCORDION_VERTICAL,
  AUDIO_LIST,
  BANNER_CAROUSEL,
  BANNER_LIST,
  BRICK,
  BUILDING_LOCATION,
  BUTTON,
  CALENDAR,
  CARD_LIST,
  CARDS_CAROUSEL,
  CAREER_FEED,
  CONTACT_FORM,
  DIAGONAL_BANNER,
  DIARIES_LIST,
  EVENTUALITIES_LIST,
  FORM_CONNECT,
  GENERAL_FORM,
  HERO,
  ICONS_CAROUSEL,
  ICONS_TEXT,
  INFO_CARDS,
  LOGOS_SECTION,
  MAP,
  MAPS,
  NOVELTIES_LIST,
  PDF,
  PROFESSIONALS,
  QUOTE,
  SINGLE_IMAGE,
  SOCIAL_MEDIAS,
  TABS,
  TEXT_COLUMN,
  TEXT_CONTENT_ORG_UNITS,
  TEXT_CONTENT_PAGES,
  TRANSITION,
  VIDEO,
  VIDEO_BACKGROUND
} from '../utils/variables/dynamicZoneComponents'
import { Accordion } from '../components/main-render/internal-pages/accordion'
import { Audio } from '../components/main-render/internal-pages/audio'
import BannerList from '../components/bannerList'
import { Button } from '../components/button'
import { Calendar } from '../components/main-render/internal-pages/calendar'
import { CardList } from '../components/home/cardList'
import CardsCarousel from '../components/cardsCarousel'
import CareerFeedComponent from '../components/main-render/careerFeedComponent'
import Carousel from '../components/carousel'
import { ContactForm } from '../components/contactForm'
import DiagonalBanner from '../components/diagonalBanner'
import { Eventualities } from '../components/main-render/internal-pages/eventualities'
import { Formulario } from '../components/form'
import Hero from '../components/hero'
import IconsCarousel from '../components/iconsCarousel'
import { IconsInfo } from '../components/main-render/internal-pages/iconsText'
import { Location } from '../components/home/location'
import LogosSection from '../components/logosSection'
import { Map } from '../components/main-render/internal-pages/map'
import { Maps } from '../components/maps'
import Mosaic from '../components/main-render/mosaic'
import NoticesAndNovelties from '../components/noticesAndNovelties'
import { PdfPreview } from '../components/main-render/internal-pages/pdfPreview'
import { Professionals } from '../components/main-render/internal-pages/professionals'
import { Publication } from '../components/main-render/internal-pages/publication'
import Quote from '../components/quote'
import Schedule from '../components/schedule'
import { SingleImage } from '../components/main-render/internal-pages/singleImage'
import Social from '../components/social'
import { TabsContent } from '../components/main-render/internal-pages/tabsContent'
import TextColumn from '../components/textColumn'
import TextContent from '../components/main-render/textContent'
import Transition from '../components/transition'
import { Video } from '../components/main-render/internal-pages/video'
import VideoBackground from '../components/videoBackground'
import { visibleLengthFilter } from './visibleLengthFilter'

const DynamicZone = ({ components }) => {
  return components.map((component) => {
    switch (component.strapi_component) {
      case ACCORDION_VERTICAL:
        return (
          component.accordion && (
            <Accordion data={component.accordion} title={component.title} />
          )
        )
      case AUDIO_LIST:
        return (
          component.audioList &&
          component.visible && (
            <Audio
              audio={component.audioList}
              audiosVisibleLength={visibleLengthFilter(
                component,
                component.audioList
              )}
              key={`audioId-${component.id}`}
            />
          )
        )
      case BANNER_CAROUSEL:
        return (
          component.slide &&
          component.visible && (
            <div
              className="w-100 mb-5 d-flex flew-column banner-carousel container"
              key={`carouselId-${component.id}`}
            >
              <Carousel carousel={component} />
            </div>
          )
        )
      case BANNER_LIST:
        return (
          component.BannerListContent && (
            <BannerList data={component.BannerListContent} />
          )
        )
      case BRICK:
        return (
          component.brick &&
          component.brick.visible && (
            <Publication
              data={component.brick}
              key={`brickId-${component.id}`}
            />
          )
        )
      case BUILDING_LOCATION:
        return (
          component.buildingsLocationList &&
          component.buildingsLocationList.visible && (
            <Location data={component.buildingsLocationList} />
          )
        )
      case BUTTON:
        return (
          component.button &&
          component.customButton && (
            <Button
              icon={component?.icon}
              button={component.button}
              customButton={component.customButton}
              key={`buttonId-${component.id}`}
            ></Button>
          )
        )
      case CALENDAR:
        return (
          component.calendar &&
          component.calendar.code.visible && (
            <Calendar
              calendarPreview={component.calendar}
              key={`calendarId-${component.id}`}
            />
          )
        )
      case CARD_LIST:
        return (
          component.cards &&
          component.visible && (
            <div className='container'>
              <CardList
                data={component}
                isLivingVocationData={true}
                key={`cardListId-${component.id}`}
              />
            </div>
          )
        )
      case CARDS_CAROUSEL:
        return (
          component.carouselCard && (
            <CardsCarousel
              title={component.title}
              cards={component.carouselCard}
            />
          )
        )
      case CAREER_FEED:
        return (
          component.careerFeedContent && (
            <div className='mb-5 container'>
              <CareerFeedComponent
                title={component.careerFeedContent.title}
                careerLevelFilter={component.careerFeedContent.career_level?.name}
                careerPlacesFilter={component.careerFeedContent.ucc_career_place} 
                careerModalityFilter={component.careerFeedContent.modality}
              />
            </div>
          )
        )
      case CONTACT_FORM:
        return (
          component.text && (
            <ContactForm
              title={component.title}
              text={component.text}
              formTitle={component.formTitle}
            />
          )
        )
      case DIAGONAL_BANNER:
        return (
          component.diagonalContent && (
            <DiagonalBanner data={component.diagonalContent}/>
          )
        )
      case DIARIES_LIST:
        return (
          component.schedule &&
          component.visible && (
            <Schedule
              diaries={component.schedule.diary}
              isHomeUcc={true}
              title={component.title}
              key={`diariesListId-${component.id}`}
            />
          )
        )
      case EVENTUALITIES_LIST:
        return (
          component.eventualitiesList &&
          component.visible && (
            <Eventualities
              eventualitiesName={component.title}
              eventualities={component.eventualitiesList}
              eventualityVisibleLength={visibleLengthFilter(
                component,
                component.eventualitiesList
              )}
              key={`entualityId-${component.id}`}
            />
          )
        )
      case GENERAL_FORM:
      case FORM_CONNECT:
        return (
          component.form &&
          component.form.id !== null && (
            <Formulario
              idForm={component.form.id}
              title={component.title}
              key={`formId-${component.form.id}`}
              receiverGeneral={component.interest_receiver?.receiver}
              typeGeneral={component.interest_type}
            />
          )
        )
      case HERO:
        return component.heroContent && (
          <Hero data={component.heroContent} />
        )
      case ICONS_CAROUSEL:
        return (
          component.icons && (
            <IconsCarousel
              title={component.title}
              icons={component.icons}
            />
          )
        )
      case ICONS_TEXT:
        return (
          component.iconTexts && (
            <IconsInfo data={component.iconTexts} />
          )
        ) 
      case INFO_CARDS:
        return (
          component.cards &&
          component.visible && (
            <Mosaic data={component} key={`infoCardId-${component.id}`} />
          )
        )
      case LOGOS_SECTION:
        return (
          component.logoSection && <LogosSection data={component.logoSection} />
        )
      case MAP:
        return (
          component.map &&
          component.map.visible && (
            <Map map={component.map} key={`mapId-${component.id}`} />
          )
        )
      case MAPS:
        return (
          component.MapContent &&
          component.visible && (
            <Maps title={component.title ?? ""} maps={component.MapContent} key={`mapId-${component.id}`} />
          )
        )
      case NOVELTIES_LIST:
        return (
          component.novelties &&
          component.visible && (
            <NoticesAndNovelties
              novelties={component.novelties}
              isHomeUcc={true}
              title={component.title}
              latest={component.latest}
              showView={components.showView}
              key={`noveltiesListId-${component.id}`}
            />
          )
        )
      case PDF:
        return (
          component.pdf &&
          component.pdf.visible && (
            <PdfPreview
              pdfPreview={component.pdf}
              key={`pdfId-${component.id}`}
            />
          )
        )
      case PROFESSIONALS:
        return (
          component.profesionals &&
          component.visible && (
            <Professionals
              title={component.title}
              professionals={component.profesionals}
              key={`professionalId-${component.id}`}
            />
          )
        )
      case QUOTE:
        return (
          component.quoteContent &&
          component.quoteContent.professional?.name != null && (
            <Quote data={component.quoteContent} />
          )
        )
      case SINGLE_IMAGE:
        return (
          component.singleImage?.image?.url &&
          component.singleImage.visible && (
            <SingleImage
              singleImage={component.singleImage}
              key={`singleImageId-${component.id}`}
            />
          )
        )
      case SOCIAL_MEDIAS:
        return (
          component.socialNetworks &&
          component.socialNetworks.visible && (
            <Social
              socialMedias={component.socialNetworks}
              key={`socialMediaId-${component.id}`}
            />
          )
        )
      case TABS:
        return (
          component.tabs &&
          component.visible && (
            <TabsContent
              data={component.tabs}
              title={component.title}
              key={`tabId-${component.id}`}
            />
          )
        )
      case TEXT_COLUMN:
        return (
          component.leftText &&
          component.rightText && (
            <TextColumn
              title={component.title}
              leftText={component.leftText}
              rightText={component.rightText}
            />
          )
        )
      case TEXT_CONTENT_ORG_UNITS:
      case TEXT_CONTENT_PAGES:
        return (
          component.textContent &&
          component.textContent.visible && (
            <TextContent
              key={`textComponentId-${component.textContent.id}`}
              component={component}
            />
          )
        )
      case TRANSITION:
        return (
          component.transitionContent && (
            <Transition
              text={component.transitionContent.text}
              image={component.transitionContent.image}
              imagePosition={component.transitionContent.imagePosition}
              color={component.transitionContent.color}
            />
          )
        )
      case VIDEO:
        return (
          component.video &&
          component.video.visible && (
            <Video
              videoComponent={component.video}
              key={`videoId-${component.id}`}
            />
          )
        )
      case VIDEO_BACKGROUND:
        return (
          component.videoBack && (
            <VideoBackground
              video={component.videoBack.video}
              description={component.videoBack.description}
              button={component.videoBack.buttonVideo}
              strapi={component.strapi_component}
              id={component.id}
              backgroundImage={component.videoBack.backgroundImage}
              videoUrl={component.videoBack.videoUrl}
            />
          )
        )
      default:
        return null
    }
  })
}

export default DynamicZone
